import * as React from 'react';

const Logo = (props) => {
    return (
        <>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                x={0}
                y={0}
                viewBox="0 0 338.21 94.85"
                {...props}
                >
                    <defs>
                        <style>
                            {'.cls-1{fill:#507cbe;}.cls-2{fill:#ededed;}.cls-3{fill:#fff;'}
                        </style>
                    </defs>
                    <polygon 
                        class="cls-1" 
                        points="178.41 51.45 197.38 62 197.38 55.33 184.29 48.51 197.38 41.69 197.38 34.96 178.41 45.57 178.41 51.45"
                    />
                    <polygon 
                        class="cls-1" 
                        points="255.81 45.57 236.84 34.96 236.84 41.69 249.93 48.51 236.84 55.33 236.84 62 255.81 51.45 255.81 45.57"
                    />
                    {/* <rect 
                        class="cls-2" 
                        x="10.55" 
                        y="15.07" 
                        width="8.1" 
                        height="66.83"
                    /> */}
                    <rect 
                        class="cls-2" 
                        x="10.55" 
                        y="15.07" 
                        width="8.1" 
                        height="66.83"
                    />
                    <path 
                    class="cls-2" 
                    d="M38.82,15.07H24.75V81.89H38.82c11.34,0,17.59-7.19,17.59-20.25V35.24C56.41,22.23,50.16,15.07,38.82,15.07ZM38.91,74H32.68V23h6.23c7.83,0,9.48,6.7,9.48,12.32V61.55C48.39,69.67,45.11,74,38.91,74Z"
                    />
                    <path 
                        class="cls-2" 
                        d="M79.74,15.07H70L56.76,81.89h7.69l2.09-10.77h15.9l2,10.77h8.11L79.79,15.3ZM81.08,64H67.91L74.66,29.4Z"
                    />
                    <polygon 
                        class="cls-2" 
                        points="110.12 23 122.4 23 122.4 81.89 130.24 81.89 130.24 23 142.52 23 142.52 15.07 110.12 15.07 110.12 23"
                    />
                    <path 
                        class="cls-2" 
                        d="M178.49,75.93c-1.55,0-2.3-1-2.3-3V58.17c0-5.55-1.52-8.3-5.23-9.38,4-1.65,5.73-5.29,5.73-11.75V30.27c0-10.48-4.53-15.58-13.83-15.58H146.62V81.52h8.1V52h7.81c5,0,5.56,2.5,5.56,8.23V73c0,6.35,2.69,9.31,8.47,9.31a6.37,6.37,0,0,0,4.64-1.6l.08-.08v-6l-.48.42A3.28,3.28,0,0,1,178.49,75.93ZM154.55,22.37h7.81c4.58,0,6.9,2.86,6.9,8.48v6.94c0,5-2.06,7.39-6.48,7.39h-8.23Z"
                    />
                    <path 
                        class="cls-2" 
                        d="M276.63,15.07h-9.75L253.65,81.89h7.69l2.09-10.77h15.89l2,10.77h8.11L276.68,15.3ZM278,64H264.8l6.74-34.62Z"
                    />
                    <polygon 
                        class="cls-2" 
                        points="318.7 15.07 318.7 59.98 302.14 15.26 302.07 15.07 292.3 15.07 292.3 81.89 299.73 81.89 299.73 31.29 319.21 81.89 325.88 81.89 325.88 15.07 318.7 15.07"
                    />
                    <path 
                        class="cls-3" 
                        d="M217.11,13.94c-10.42,0-16.16,6.57-16.16,18.5V64.77c0,11.87,5.74,18.42,16.16,18.42s16.25-6.55,16.25-18.42V32.44C233.36,20.51,227.59,13.94,217.11,13.94Zm-8.06,17.91c0-6.49,2.86-10.07,8.06-10.07s8.15,3.58,8.15,10.07V65.27c0,6.44-2.9,10-8.15,10s-8.06-3.64-8.06-10Z"
                    />
                    <polygon 
                        class="cls-1" 
                        points="228.33 8.48 199.78 85.64 199.68 85.9 205.74 88.48 234.53 11.17 228.33 8.48"
                    />
                    </svg>
        </>
    )
}

export default Logo;